body {
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
  font-variant: tabular-nums;
  line-height: 1.5715;
}
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 0px;
  margin-top: 20px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.footerImgHeight{
  height: 40px;
}
.topNav{
  background-color: #990000 !important;
  padding: 0 0.5rem 0 0.5rem;
  color: white;
  margin: 0 !important;
}
.navbar{
  background-color: rgba(0,0,0,.85);
  font-weight: 700;
}

* {
 box-sizing: border-box;
}
*:before,
*:after {
 box-sizing: border-box;
}

html,
body {
 height: 100%;
 position: relative;
}
.main-container {
 min-height: 100vh; /* will cover the 100% of viewport */
 overflow: hidden;
 display: block;
 position: relative;
 padding-bottom: 100px; /* height of your footer */
}

table thead .caret {
display: inline-block;
width: 0;
height: 0;
margin-left: 2px;
vertical-align: middle;
border-top: 4px dashed;
border-top: 4px solid \9;
border-right: 4px solid transparent;
border-left: 4px solid transparent;
}

table thead .dropup .caret, table thead .navbar-fixed-bottom .dropdown .caret {
border-top: 0;
border-bottom: 4px dashed;
border-bottom: 4px solid \9;
content: "";
}

.form-control {
font-size: 0.8rem;
}
.table td, .table th {
  padding: .25rem .75rem .25rem .75rem;
}
header {
  text-transform: capitalize;
}
.inlineFlex{
  display: inline-flex;
  min-height: calc(100vh - 42px);
  max-width: 100%;
  width: 100%;
}
.sideNav {
  height: inherit;
  box-shadow: 8px 0px 0px 0px white;
  background-color: #990000;
  width: 120px;
}
.navbar-light .navbar-brand {
    color: white;
}
.navbar-light .navbar-brand:hover {
    color: white;
}
.container {
  margin: 10px;
}
.page-item.active .page-link {
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-primary {
  background-color: #990000;
  border-color: #545b62;
}
.btn-primary:hover {
  background-color: #990000;
  border-color: #545b62;
}
.button {
  border: 4px;
}
.topNavLink{
  color: white !important;
}
.topNavLink:hover{
  background: #951022!important;
  color: #fff!important;
}
.sideNavList {
  margin-left: 10px;
}
.sideNavList a {
  color: white;
}
.sideNavList a:hover {
  color: #e31933 !important;
}
.topNav a:hover {
  color: white !important;
}
a:hover {
    color: white !important;
}
a {
  color: white;
}
.nav-link.disabled {
  color: white;
}
.row {
  margin: 0px;
}
.resourceTable {
  font-size: 0.8rem;
  width: calc(100% - 160px);
  margin: 20px;
  max-height: calc(100vh - 82px);
  overflow: auto;
}
.overflowAutoScroll {
  overflow: auto;
}
.logoBackground {
  background-image: url(./logo_smith.png);
  background-repeat: no-repeat;
  background-color: #231f20;
  background-position: center;
  max-width: 100%;
  height: 120px;
  bottom: 0;
  left:0;
  width:100%;
  position: fixed;
}
.borderBlack {
  border: 1px solid black;
}
table thead tr th {
   text-align: left;
   position: sticky;
   top: 0px;
   background-color: #666;
   color: #fff;
 }
 table {
   overflow: auto;
 }

td, th {
  text-align: left;
  padding: 8px;
  border: 1px solid black;
}
tr:hover{
  background-color:#f5f5f5;
}

.column {
  float: left;
  width: 33.33%;
  padding: 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

td button {
  width: 65px;
}

.width100 {
  width: 100%;
}
.p20{
  padding: 20px;
}
.p10{
  padding: 10px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.loader {
  background: url('./testudo_icon.gif') no-repeat center center;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   z-index: 1;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 15px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
  font-weight: bold;
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
  border: 1px solid #ccc;
}

/* Fade in tabs */
@-webkit-keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

.rankTable tr:nth-child(even) {background-color: #f2f2f2;}

.rankTable td, th {
  border: none;
}

.rankTable tr:hover{
  background-color:rgb(220, 220, 220);
}

.colRed {
  color: red;
}

.colGreen {
  color: green;
}

.summaryTable {
  border: 2px solid white;
}

.summaryTable tr:nth-child(odd) {
  background-color: white;
}

.summaryTable td, th {
  border: 1px solid black;
}

.summaryTable tr:hover{
  background-color:rgb(220, 220, 220);
}

.summaryLoader {
  /* border: 16px solid #ffff;
  border-radius: 50%;
  border-top: 16px solid #990000;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: relative;
  margin: 30px 0 15px 0;
  left: 50%; */
  background: url('./testudo_icon.gif') no-repeat center center;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   z-index: 1;
}

.selectedTab {
  background: white;
  color: #990000 !important;
}
.dropdown-item a {
  color: #212529;
}

.dropdown-item a:hover {
    color: black !important;
}

.csvExportButton {
  float: right;
  border: 1px solid black;
  margin-bottom: 10px;
}

.react-bootstrap-table {
  overflow: auto;
}

.resourceBtnTable {
  display: inline-block;
  width: 100%;
}

td button {
  width: auto;
}

.metricsWidgetHeading {
  font-weight: bold;
  color: #E21833;
  font-size: 20px;
}

.metricsWidgetBody {
  padding: 2.5px 0 2.5px 0;
  font-size: 17px;
}
